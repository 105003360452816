import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js';
import 'photoswipe/dist/photoswipe.css';

const options = {
    dataSource: [],
    mainClass: 'pswp--custom pswp--custom-banner',
    bgOpacity: 0.6,
    arrowPrevSVG: '<i class="ic ic-angle-left"></i>',
    arrowNextSVG: '<i class="ic ic-angle-right"></i>',
    showHideAnimationType: 'none',
    wheelToZoom: false,
    close: false,
    pswpModule: PhotoSwipe
};

window.onload = function() {
    if ($('.loading').length) {
        $('.loading').delay(2800).fadeOut(function() {
            $('body').addClass('loaded');
            pageLoaded()
        });
    } else {
        $('body').addClass('loaded');
        pageLoaded()
    }
};

function pageLoaded() {
    $.ajax({
        method: "GET",
        url: App.homeUrl + '/api/banner',
    })
    .done(function( response ) {
        if (response.datas.length) {
            options.dataSource = response.datas;
            let myTimeout;
            const autoplayDuration = 4000;
            const lightbox = new PhotoSwipeLightbox(options);
            lightbox.init();
            lightbox.on("afterInit", () => {
                const pswp = lightbox.pswp;
                document.querySelector(".pswp").addEventListener("wheel", (e) => {
                    ["scrollable"].forEach(className => { // "class" appears to be reserved
                        if (e.target.classList.contains(className) || e.target.closest(`.${className}`)) {
                            e.stopPropagation();
                        }
                    });
                }, { capture: true, passive: true });
                document.querySelector(".pswp").addEventListener("click", (e) => {
                    ["pswp__button--close"].forEach(className => { // "class" appears to be reserved
                        if (e.target.classList.contains(className) || e.target.closest(`.${className}`)) {
                            pswp.close();
                        }
                    });
                }, { capture: true, passive: true });
                document.querySelector(".pswp").addEventListener("mouseenter", (e) => {
                    ["scrollable"].forEach(className => { // "class" appears to be reserved
                        if (e.target.classList.contains(className) || e.target.closest(`.${className}`)) {
                            // console.log('mouseenter');
                            clearTimeout(myTimeout);
                        }
                    });
                }, { capture: true, passive: true });
                document.querySelector(".pswp").addEventListener("mouseleave", (e) => {
                    ["scrollable"].forEach(className => { // "class" appears to be reserved
                        if (e.target.classList.contains(className) || e.target.closest(`.${className}`)) {
                            // console.log('mouseleave');
                            clearTimeout(myTimeout);
                            myTimeout = setTimeout(function() {
                                pswp.next();
                            }, autoplayDuration);
                        }
                    });
                }, { capture: true, passive: true });
            });
            lightbox.on('openingAnimationEnd', () => {
                // console.log('openingAnimationEnd');

                const pswp = lightbox.pswp;
                clearTimeout(myTimeout);
                myTimeout = setTimeout(function() {
                    pswp.next();
                }, autoplayDuration);
            });
            lightbox.on('change', () => {
                // console.log('change');

                const pswp = lightbox.pswp;
                clearTimeout(myTimeout);
                myTimeout = setTimeout(function() {
                    pswp.next();
                }, autoplayDuration);
            });
            lightbox.on('close', () => {
                // PhotoSwipe starts to close, unbind most events here
                // console.log('close');
                clearTimeout(myTimeout);
            });
            lightbox.loadAndOpen(0);
        }
    });
}
